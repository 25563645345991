@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;

#businessPageDropdown {
    
  display: table;
  margin: 0 auto;
  padding-top:10px;
}

#businessPageSpace {
    
  display: table;
  margin: 0 auto;
  padding-top:100px;
}
.ka-table-wrapper{
  max-height: 75vh;
  min-height: 75vh;
  overflow: auto;

}

#embeddingContainer{
  height: 100%;
  width: 100%;
  display: block;
}

iframe{
  width: 100%;
  display: block;
}

.custom-home-text-inverted {
  color: awsui.$color-text-home-header-default;
}

.homePageLink {
  color: awsui.$color-text-home-header-default;
  text-decoration: none;
}

.custom-home-text-secondary {
  color: awsui.$color-text-home-header-secondary;
}

@media (min-width: 992px) {
  .custom-home__sidebar {
    margin-top: -6rem;
  }
}

.custom-home-image__placeholder:before {
  content: 'X';
  display: block;
  background-color: #f2f3f3;
  color: #ffffff;
  text-align: center;
  font-size: 40rem;
  line-height: 40rem;
}


.custom-list-separator {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    border-top: 1px solid awsui.$color-border-divider-default;
    padding: 0.8rem 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:first-child {
      padding-top: 0;
      border-top: none;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
